export const firebaseConfig = {
  apiKey: "AIzaSyCtbSimghywkMiWI-2DFsvfE8vLyoZFLHQ",
  authDomain: "martinapassanante-a1ecc.firebaseapp.com",
  projectId: "martinapassanante-a1ecc",
  storageBucket: "martinapassanante-a1ecc.appspot.com",
  messagingSenderId: "715328293442",
  appId: "1:715328293442:web:ef53182c4af0a1ee0bfbdb",
  measurementId: "G-T2X3QWN6LK"
};

export const BREAKPOINT_XS = 768 - 1;
export const BREAKPOINT_SM = 992 - 1;
export const BREAKPOINT_MD = 1400 - 1;
export const LAYOUT_XS = "XS";
export const LAYOUT_SM = "SM";
export const LAYOUT_MD = "MD";
export const LAYOUT_LG = "LG";
export const PAGESIZE = 20;