import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsDressageBasic = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Dressage (Base) - Percorsi" : "xxx"}
        languages={{"it": "paths/dressage-basic"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-dressage-basic.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Dressage (Base)" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Il Dressage è una specialità degli sport equestri nella quale cavallo e cavaliere eseguono una serie prestabilita di figure e movimenti in campo.</Typo>
            <Typo type="p" color="#ffffff">Ottima sfida per testare la precisione della comunicazione con il cavallo e la vostra intesa, si può sempre migliorare... Ed è anche un validissimo allenamento per mantenere la forma fisica!</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">In che modo diventa etico?</Typo>
            <Typo type="p">
              <ul>
                <li>Nessun cavallo verrà mai forzato o obbligato ad eseguire gli esercizi con noi, nel Dressage tradizionale viene richiesta la sottomissione del cavallo per valutare il grado di addestramento mentre alla base della nostra attività ci sono comunicazione, collaborazione e coinvolgimento attivo del cavallo.</li>
                <li>Lavoreremo sull'apprendimento del cavallo, che non eseguirà gli esercizi in modo automatico, ma sarà parte attiva del procedimento.</li>
                <li>Non vengono utilizzati strumenti coercitivi come imboccature e speroni.</li>
                <li>Non vengono ripetuti gli stessi esercizi all’infinito ma l’attività è dinamica con figure, pause e premi, attività da terra e da sella, attività in campo e fuori dal campo.</li>
                <li>Tutti i cavalli coinvolti nell’attività sono pronti fisicamente e mentalmente e l'attività è tarata in maniera personalizzata per ogni soggetto umano ed equino.</li>
                <li>Chi non ha il proprio cavallo farà attività sempre con lo stesso cavallo che gli verrà affidato dal centro, senza cambiarlo ogni volta, in modo da conoscersi in modo sempre più approfondito e migliorare di volta in volta.</li>
              </ul>
            </Typo>            
        
            <hr/>

            <Typo type="h3">Obiettivi del percorso</Typo>
            <Typo type="p">
              <ul>
                <li>Imparare a comunicare con il cavallo in modo efficace e preciso per fare proposte e richieste in modi diversi a seconda del cavallo e delle sue capacità di apprendimento.</li>
                <li>Rafforzare la relazione con il cavallo e con il resto del gruppo.</li>
                <li>Imparare a conoscere e a realizzare figure di Dressage, intere riprese, freestyles e caroselli nel rispetto del cavallo.</li>
              </ul>
            </Typo>        

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Questo corso fa per te se...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Sei sempre stato appassionato del Dressage come disciplina tradizionale ma non sei più disposto a scendere a compromessi per il benessere del cavallo.</li>
                  <li>Vuoi diventare sempre più preciso nella comunicazione con il tuo cavallo o con un cavallo del centro investendo nella vostra relazione.</li>
                  <li>Vuoi approfondire vari tipi di apprendimento per insegnare nuove conoscenze e competenze al cavallo.</li>
                  <li>Vuoi conoscere nuovi esercizi da proporre al cavallo, utili per il suo fisico, la sua mente e la vostra comunicazione.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Cosa faremo durante le lezioni?</Typo>
            <Typo type="p">Il Progetto Dressage prevede un programma completo per cavallo e cavaliere:
              <ul>
                <li>Allenamento graduale, ginnastica e propriocezione del cavallo.</li>
                <li>Allenamento graduale, ginnastica e propriocezione del cavaliere.</li>
                <li>Lavoro sull'assetto del cavaliere.</li>
                <li>Preparazione e lavoro da terra.</li>
                <li>Lavoro in campo e fuori dal campo sulle figure di maneggio.</li>
                <li>Socializzazione tra i cavalli per il lavoro di gruppo.</li>
                <li>Lavoro singolo, in sezione e in coordinazione con il gruppo.</li>
                <li>Studio e realizzazione di riprese di Dressage ufficiali.</li>
                <li>Ideazione e realizzazione di freestyles, ovvero una serie di figure e movimenti scelti liberamente eseguiti a ritmo di musica e caroselli, ovvero riprese di gruppo organizzate e composte per creare incroci dinamici.</li>
              </ul>
            </Typo>
            

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">-</Typo>

              {/* <Typo type="h0" color="#ffffff" align="center">Date</Typo>
              <Typo type="p" color="#ffffff" align="center">
                <b>1°</b> - 17 marzo 2024 - 09:00-11:00<br/>
                <b>2°</b> - 13 aprile 2024 - 15:00-17:00<br/>
                <b>3°</b> - 28 aprile 2024 - 09:00-11:00<br/>
                <b>4°</b> - 11 maggio 2024 - 17:00-19:00<br/>
                <b>5°</b> - 26 maggio 2024 - 09:00-11:00<br/>
                <b>6°</b> - Da definire<br/>
                <b>7°</b> - Da definire<br/>
                <b>8°</b> - Da definire<br/>
              </Typo> */}

              <Typo type="h0" color="#ffffff" align="center">Iscrizione</Typo>
              <Typo type="p" color="#ffffff" align="center">Tramite Whatsapp<br/>al n° <a href="https://wa.me/393461330641" target="_blank" rel="noreferrer">+39 346 1330641</a></Typo>

              {/* <Typo type="h0" color="#ffffff" align="center">Presentazione e lezione di prova</Typo>
              <Typo type="h3" color="#ffffff" align="center">10 dicembre 2023</Typo> */}
              
              <Typo type="h0" color="#ffffff" align="center">Impegno</Typo>
              <Typo type="note" color="#ffffff" align="center">Questo percorso richiede un impegno di 2 volta al mese, per circa 2 ore.</Typo>             
              <Typo type="note" color="#ffffff" align="center">In questo modo, tra un incontro e l'altro avrete il tempo di lavorare con il vostro cavallo per fissare le competenze impostate ad ogni lezione. Avrete quindi la possibilità, all'incontro successivo, di porre domande, affrontare dubbi e difficoltà, e quindi lavorare per risolvere ogni problema.</Typo>             
              
            </Box>
            <img src="/photos/thumbnails/percorso-dressage-basic.jpg" alt="Dressage (Base)" width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Aver concluso con successo l'attività Inizio Del Viaggio (IDV).</Typo>
              <Typo type="note" align="center">Saper comunicare con gentilezza ed efficacia con il cavallo.</Typo>
              <Typo type="note" align="center">Essere indipendente nella preparazione del cavallo.</Typo>
              <Typo type="note" align="center">Fino all'inizio del corso c'è la possibilità di fare lezioni per arrivare ai requisiti minimi richiesti per la partecipazione!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsDressageBasic;
