import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const PathsMockExcursion = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Escursione Simulata - Percorsi" : "xxx"}
        languages={{"it": "paths/mock-excursion"}}
      />
      <Section variant="primary" image={"/photos/headers/percorso-escursione-simulata.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Percorso</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Escursione Simulata" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Questo percorso è pensato per le persone che non hanno ancora raggiunto i requisiti minimi (di età o di competenze) per partecipare alle escursioni esterne e sarà una vera e propria escursione, ma simulata.<br/>
            In questo modo restaremo all'interno del centro, in sicurezza, riproducendo tutte le modalità che servono per l'escursione vera e propria e tutte le difficoltà e gli imprevisti che si potrebbero trovare all'esterno.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={8}>
            <Typo type="h3">In che modo diventa etico?</Typo>
            <Typo type="p">
              <ul>
                <li>La sicurezza ed il benessere di tutti i partecipanti sono messe sempre al primo posto.</li>
                <li>Il gruppo è inclusivo, tutti gli umani e i cavalli possono partecipare.</li>
                <li>I cavalli si conoscono prima di iniziare.</li>
                <li>L'andatura più utilizzata, così come avverrebbe in natura, è il passo.</li>
                <li>Non viene utilizzato nessuno strumento coercitivo (frustini, speroni, ecc).</li>
                <li>Sono sempre previste pause per recuperare, rilassarsi, godere dell'ambiente e del paesaggio e brucare con calma.</li>
              </ul>
            </Typo>            
        
            <hr/>

            <Typo type="h3">Obiettivi del percorso</Typo>
            <Typo type="p">
              <ul>
                <li>Composizione e crescita di un bel gruppo di umani ed equini che collaborano e si ascoltano, che si aiutano e si mettono alla prova.</li>
                <li>Essere in grado di uscire in passeggiata in sicurezza, incentivando la calma e la curiosità del cavallo, migliorando la relazione con lui.</li>
                <li>Le escursioni sono un momento di svago e di scoperta per tutti e per ciascuno con le proprie peculiarità (sono un’occasione per scoprire le differenze di specie e per appagare le motivazioni).</li>
                <li>Le escursioni permettono di far emergere e valorizzare le risorse e qualità individuali.</li>
              </ul>
            </Typo>  

            <hr/>

            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Questo corso fa per te se...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Non vedi l’ora di uscire in passeggiata ma non hai ancora l'età o le competenze per poterlo fare.</li>
                  <li>Vuoi approfondire le tue conoscenze e competenze ma non ti senti ancora sicuro ad uscire in passeggiata o in escursione.</li>
                  <li>Vuoi approfondire la comunicazione e la relazione con il tuo cavallo in un contesto nuovo ma restando in sicurezza.</li>
                  <li>Hai conosciuto l'Equitazione Etica e vuoi approfondirne i concetti.</li>
                </ul>
              </Typo> 
            </Box>
            
            <hr/>

            <Typo type="h3">Cosa faremo durante le lezioni?</Typo>
            <Typo type="p">
              <ul>
                <li>Impareremo a comunicare in modo chiaro ed efficace con il cavallo, requisito fondamentale per le escursioni.</li>
                <li>Esercizi di gruppo per imparare a comunicare non solo con il nostro cavallo ma anche con il resto del gruppo di umani.</li>
                <li>Preparazione del gruppo ai possibili imprevisti che si possono trovare anche all’esterno.</li>
                <li>Simulazioni di tutto ciò che è necessario sapere, proprio come se fossimo in escursione fuori dal centro, ma con la garanzia di essere in sicurezza.</li>
                <li>Esercizi e percorsi sia da terra che, per chi lo desidera, in sella.</li>
              </ul>
            </Typo>

          </Col>
          <Col widthmd={4}>

            <Box variant="primary"> 
              <Typo type="h3" color="#ffffff" align="center">Calendario</Typo>
              <Typo type="h0" color="#ffffff" align="center">Prossima data d'inizio</Typo>
              <Typo type="h3" color="#ffffff" align="center">-</Typo>

              {/* <Typo type="h0" color="#ffffff" align="center">Date (Gruppo A)</Typo>
              <Typo type="p" color="#ffffff" align="center">
                <b>1°</b> - 23 marzo 2024 - 15:00-18:00<br/>
                <b>2°</b> - 27 aprile 2024 - 15:00-18:00<br/>
                <b>3°</b> - 12 maggio 2024 - 09:00-12:00<br/>
                <b>4°</b> - 01 giugno 2024 - 16:00-19:00<br/>
                <b>Rec:</b> - 16 giugno 2024 - 08:00-11:00<br/>
                <b>5°</b> - Da definire<br/>
                <b>6°</b> - Da definire<br/>
                <b>7°</b> - Da definire<br/>
                <b>8°</b> - Da definire<br/>
                <b>Rec:</b> - Da definire<br/>
              </Typo>

              <Typo type="h0" color="#ffffff" align="center">Date (Gruppo B)</Typo>
              <Typo type="p" color="#ffffff" align="center">
                <b>1°</b> - 20 aprile 2024 - 15:00-18:00<br/>
                <b>2°</b> - 04 maggio 2024 - 15:00-18:00<br/>
                <b>3°</b> - 25 maggio 2024 - 15:00-18:00<br/>
                <b>4°</b> - 15 giugno 2024 - 16:00-19:00<br/>
                <b>Rec:</b> - 16 giugno 2024 - 08:00-11:00<br/>
                <b>5°</b> - Da definire<br/>
                <b>6°</b> - Da definire<br/>
                <b>7°</b> - Da definire<br/>
                <b>8°</b> - Da definire<br/>
                <b>Rec:</b> - Da definire<br/>
              </Typo> */}

              <Typo type="h0" color="#ffffff" align="center">Iscrizione</Typo>
              <Typo type="p" color="#ffffff" align="center">Tramite Whatsapp<br/>al n° <a href="https://wa.me/393461330641" target="_blank" rel="noreferrer">+39 346 1330641</a></Typo>

              <Typo type="h0" color="#ffffff" align="center">Impegno</Typo>
              <Typo type="note" color="#ffffff" align="center">Questo percorso richiede un impegno indicativamente di 1 volta al mese, per circa 3 ore.</Typo>             
              <Typo type="note" color="#ffffff" align="center">In questo modo, tra un incontro e l'altro avrete il tempo di lavorare con il vostro cavallo per fissare le competenze impostate ad ogni lezione. Avrete quindi la possibilità, all'incontro successivo, di porre domande, affrontare dubbi e difficoltà, e quindi lavorare per risolvere ogni problema.</Typo>             
            </Box>
            <img src="/photos/thumbnails/percorso-escursione-simulata.jpg" alt="Escursione Esterna " width="100%" />
            <Box variant="light">              
              <Typo type="h4" align="center">Prerequisiti per partecipare</Typo>
              <Typo type="note" align="center">Essere in possesso dell'abilitazione A.</Typo>
              <Typo type="note" align="center">Aver già iniziato il proprio percorso personale di Equitazione Etica o di Equinofilia presso il nostro centro o un altro centro affiliato.</Typo>
              <Typo type="note" align="center">Fino all'inizio del corso c'è la possibilità di fare lezioni per arrivare ai requisiti minimi richiesti per la partecipazione!</Typo>             
            </Box>      
              
            
          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default PathsMockExcursion;
