import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';


import AnalyticsService from '../../services/AnalyticsService';



import Section from '../layout/Section';
import Box from '../layout/Box';
import Row from '../../common/components/Row';
import Col from '../../common/components/Col';
import Typo from '../../common/components/Typo'
import Meta from '../layout/Meta';

import Layout from '../layout/Layout';


const NuovavitaBoarding = ({layout}) => {
  let { lang } = useParams();
  

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Pensione Cavalli - Nuova Vita ASD" : "xxx"}
        languages={{"it": "nuova-vita/boarding"}}
      />
      <Section variant="primary" image={"/photos/headers/nuovavita-boarding.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Nuova Vita ASD</Typo>
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Pensione Cavalli" : "xxx"}</Typo>
            <Typo type="p" color="#ffffff">Offriamo una pensione per cavalli che combina spazi ampi, socializzazione in branco e una gestione personalizzata, per garantire benessere fisico e psicologico a ogni animale, con un approccio etico e naturale.</Typo>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <Row>
          <Col widthmd={7}>
            <Typo type="h3">Perché scegliere la nostra pensione per cavalli?</Typo>
            <Typo type="p">
              Il nostro centro, certificato dalla Scuola di Equitazione Etica, è un luogo dove i cavalli vivono in branco, seguendo una gestione naturalizzata. Offriamo pensione per cavalli con soluzioni personalizzate per le esigenze di ogni animale, garantendo il massimo benessere fisico e psicologico.
            </Typo>           
        
            <hr/>

            <Typo type="h3">Che tipo di pensione offriamo?</Typo>
            <Typo type="p">
              Ogni cavallo è seguito con attenzione per assicurare una sistemazione che rispetti al meglio il suo benessere e le sue specifiche esigenze.
            </Typo>        
            <Typo type="p">
              La nostra pensione è organizzata per garantire la vita in branco a tutti i cavalli, in un ambiente naturale che favorisce il benessere psicofisico e la socializzazione. I cavalli vivono insieme in ampi spazi, con la libertà di muoversi, interagire e soddisfare le loro esigenze naturali.
              Per i cavalli che arrivano da gestioni differenti e/o che non sono abituati a vivere in branco, iniziamo con un periodo di adattamento in paddock. Durante questa fase, il cavallo viene gradualmente introdotto al gruppo con un processo di socializzazione sicuro e rispettoso delle esigenze individuali.
            </Typo>        
            <br/>
            <Typo type="h4">E se il mio cavallo ha esigenze difficili?</Typo>
            <Typo type="p">
              Alcuni cavalli, a causa di esigenze fisiche o comportamentali, potrebbero non essere compatibili con la vita del branco principale. Per loro offriamo una gestione il più vicina possibile a quella naturale, compatibilmente con le esigenze del cavallo, garantendo sempre accesso a spazi adeguati, risorse necessarie e interazioni sociali sicure, in base alle loro necessità.
            </Typo>
            <Typo type="p">
              Alcuni esempi:
              <ul>
                <li>Cavalli bolsi allergici alla polvere.</li>
                <li>Cavalli con problemi cronici (zoppia, cecità).</li>
                <li>Cavalli in riabilitazione fisica o comportamentale.</li>
                <li>Cavalli in post operatorio o con traumi.</li>
                <li>Cavalli con difficoltà a relazionarsi con altri cavalli.</li>
                <li>Cavalli non abituati alla vita di paddock che hanno bisogno di un avvicinamento alla gestione naturale.</li>
              </ul>
            </Typo>
            <br/>
            <Typo type="h4">L'attenzione per gli inserimenti</Typo>
            <Typo type="p">
              L’inserimento dei cavalli nella nostra gestione naturalizzata è un processo accuratamente seguito da personale qualificato. Ogni fase, dall’adattamento all’alimentazione fino alla socializzazione con gli altri cavalli, viene affrontata gradualmente, rispettando i tempi e le esigenze uniche di ogni cavallo.
            </Typo>    
        
            <hr/>

            
            

          </Col>
          <Col widthmd={5}>
            <Box variant="primary">
              <Typo type="h3" color="#ffffff" align="center">Nel nostro centro puoi trovare...</Typo>
              <Typo type="p" color="#ffffff">
                <ul>
                  <li>Ampi paddock drenati con ripari ed arricchimenti ambientali.</li>
                  <li>Paddock infermeria monitorato da personale e telecamere.</li>
                  <li>Grandi spazi per la socializzazione dei cavalli.</li>
                  <li>Due campetti in erba, di cui uno coperto, e numerosi spazi verdi per fare attività da terra e da sella.</li>
                  <li>Area lavaggio.</li>
                  <li>Selleria, gazebo, bagno e varie strutture per umani... E presto in arrivo anche la Club House!</li>
                </ul>
              </Typo> 
            </Box>     

            <hr/>

            <Typo type="h3">Abbiamo anche servizi per umani!</Typo>
            <Typo type="p">
              Oltre alla cura e al benessere dei cavalli, offriamo una serie di servizi dedicati ai proprietari e agli appassionati:
              <ul>
                <li>Supporto nella gestione del cavallo nei giorni in cui il proprietario non può occuparsene, ad esempio:
                  <ul>
                    <li>somministrazione di integrazioni alimentari o terapie;</li>
                    <li>assistenza durante le visite di veterinari o pareggiatori;</li>
                    <li>movimento del cavallo.</li>
                  </ul>
                </li>
                <li>Corsi e passeggiate che promuovono un approccio rispettoso e naturale, in linea con i principi dell’Equitazione Etica.</li>
                <li>Eventi e Attività Sociali come occasioni per conoscere altre persone che condividono la stessa passione per il benessere equino e una visione rispettosa della relazione uomo-cavallo.</li>
              </ul>
            </Typo>   

          </Col>
        </Row>
      </Section>
      
    </Layout>
  )
}

export default NuovavitaBoarding;
