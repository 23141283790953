import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';


import Layout from './../layout/Layout';
import Section from './../layout/Section';
// import Box from './../layout/Box';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Typo from './../../common/components/Typo';
import Button from './../../common/components/Button';
// import Shadow from './../layout/Shadow';
import Separator from './../layout/Separator';
import Meta from './../layout/Meta';
import Card from './../../common/components/Card'
// import Calendar from './../../common/components/Calendar';
// import NewsletterAvailability from './../layout/NewsletterAvailability';

// import DatabaseService from './../../services/DatabaseService';

// import {LAYOUT_SM, LAYOUT_XS } from './../../config';

const Header = styled.div`
  height: 835px;
  max-height: 80vh;
  width: 100%;
  background-image: linear-gradient(0deg, #EDDDD4 0%, rgba(255, 255, 255, 0) 79%), 
                    url("${props => props.src}");
  background-repeat: no-repeat;
  background-size: cover, cover;
  background-position: center;
`;
// const ImageFull = styled.img`
//   width: 100%;
// `;

const HomePage = ({layout}) => {
  let { lang } = useParams();

  // const [eventsNext, setEventsNext] = useState([]);

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  useEffect(() => { 
    // DatabaseService.getEvents({
    //   status: "next",
    //   order: "asc"
    // }).then((response) => {
    //   if(response.success){
    //     setEventsNext(response.data);
    //   }else{
    //     console.log(response.error);
    //   }
    // });
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        languages={{"it": "", "en":""}}
      />
      
      
      <Header src="/photos/headers/home.jpg" />
      <Separator />      

      <Section variant="light">
        <Typo type="h0" align="center">Nuova Vita ASD</Typo>
        <Typo type="h1" align="center">Martina Passanante</Typo>
        <Typo type="p" align="center">Opero principalmente nel mio centro con corsi, consulenze, lezioni individuali e di gruppo e pareggi, ma anche a domicilio in tutto il nord e centro Italia.</Typo>
        <Row>
          <Col widthmd={3}>
            <Card height="100%">
              <img src="/photos/home/centro-etico.jpg" alt="Centro Etico" width="100%" />
              <br/><br/>
              <Typo type="p" align="center" margin="5px">Gestisco un <a href="https://www.equitazioneetica.it/gn/" target="_blank" rel="noreferrer">Centro Etico Certificato</a> in gestione naturalizzata in branco</Typo>              
            </Card>
          </Col>
          <Col widthmd={3}>
            <Card height="100%">
              <img src="/photos/home/tecnico-see.jpg" alt="Tecnico SEE" width="100%" />
              <br/><br/>
              <Typo type="p" align="center" margin="5px">Sono un <a href="https://www.equitazioneetica.it/tecnici-ufficiali-della-see/" target="_blank" rel="noreferrer">Tecnico di III livello della SEE</a></Typo>              
            </Card>
          </Col>
          <Col widthmd={3}>
            <Card height="100%">
              <img src="/photos/home/consulente-equinofilia.jpg" alt="Consulente Equinofilia" width="100%" />
              <br/><br/>
              <Typo type="p" align="center" margin="5px">Sono una <a href="https://www.equinofilia.it/albo-ufficiale-professionisti-ife/" target="_blank" rel="noreferrer">Consulente Esperta di Equinofilia</a>, specializzata su tutti gli equidi</Typo>              
            </Card>
          </Col>
          <Col widthmd={3}>
            <Card height="100%">
              <img src="/photos/home/pareggiatrice-olistica.jpg" alt="Pareggiatrice Olistica" width="100%" />
              <br/><br/>
              <Typo type="p" align="center" margin="5px">Sono una pareggiatrice olistica certificata</Typo>              
            </Card>
          </Col>
        </Row>
        
      </Section>

      <Section variant="primary" image={"/photos/home/section.jpg"}>
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Scopri i nostri</Typo>
            <Typo type="h1" align="center" color="#ffffff">Percorsi ed Attività</Typo>
            <Typo type="p" color="#ffffff" align="center">Presso il centro Nuova Vita ASD, organizziamo Percorsi formativi e diverse Attività nel rispetto del cavallo, del cavaliere e mettendo sempre in primo piano la crescita personale ed emotiva del binomio.</Typo>
          </Col>
          <Col widthmd={6}>  
            <Typo type="p" color="#ffffff" align="center">I nostri <b>percorsi</b> formativi sono strutturati come una serie di lezioni che affrontano argomenti specifici, passo dopo passo, durante ciascun incontro. Verrà quindi formato un gruppo stabile per tutta la durata del percorso.</Typo>
            <center><Link to={"/"+lang+"/paths"}><Button variant="white">Scopri di più</Button></Link></center>
          </Col>
          <Col widthmd={6}>  
            <Typo type="p" color="#ffffff" align="center">Le nostre <b>attività</b> possono essere intraprese in qualsiasi momento, semplicemente prenotando una lezione sull'argomento scelto. Le lezioni si terranno in gruppi di persone e cavalli con competenze affini.</Typo>
            <center><Link to={"/"+lang+"/activities"}><Button variant="white">Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>

      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center">Diventa Socio e completa la tua</Typo>
            <Typo type="h2" align="center">Iscrizione 2025</Typo>
            <Typo type="p" align="center">La quota associativa è obbligatoria per usufruire dei servizi e partecipare all'attività dell'ASD.<br/>
            Diventando socio, avrai diritto alla tessera dell'ASD, che include l’assicurazione fornita da ASI, ente di promozione sportiva riconosciuto dal CONI. Grazie alla tessera, potrai accedere ai servizi offerti da tutte le ASD affiliate ad ASI.</Typo> 
            <center><Link to={"/"+lang+"/nuova-vita/membership"}><Button>Vai all'Area Soci</Button></Link></center>
          </Col>
        </Row>
      </Section>

      {/* <Section variant="primary">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center" color="#ffffff">Scopri la programmazione</Typo>
            <Typo type="h2" align="center" color="#ffffff">Calendario</Typo>
            <Calendar />

          </Col>
        </Row>
      </Section> */}

      <Section variant="light">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h0" align="center">Vuoi venire a conoscerci?</Typo>
            <Typo type="h2" align="center">Contattaci</Typo>
            <Typo type="p" align="center">Saremo lieti di mostrarti il centro Nuova Vita ASD e presentarti il nostro modo di vivere con i cavalli!</Typo>
            <center><Link to={"/"+lang+"/contacts"}><Button>Vai ai Contatti</Button></Link></center>
          </Col>
        </Row>
      </Section>
    </Layout>
  )
}

export default HomePage
