import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Link, useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Section from './../layout/Section';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Card from './../../common/components/Card'
import Typo from './../../common/components/Typo'
import Button from './../../common/components/Button';
import Meta from './../layout/Meta';
// import Calendar from './../../common/components/Calendar';

import Layout from '../layout/Layout';


const ScrollTrigger = styled.div`
  height: 100%;
  cursor: pointer;
  transition: transform 50ms ease-in-out;

&:hover {
  transform: rotate(2deg);
}
`;
const ScrollRef = styled.div`
  scroll-margin: 130px;
`;

const Nuovavita = ({layout}) => {
  let { lang } = useParams();
  
  // const scrollToVolteggio = useRef();
  const scrollToBoarding = useRef();
  const scrollToHorseLife = useRef();
  const scrollToMembership = useRef();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Nuova Vita ASD" : "xxx"}
        languages={{"it": "nuova-vita"}}
      />
      <Section variant="primary" image={"/photos/headers/nuovavita.jpg"}>
        <Row>
          <Col widthmd={12}>  
            {/* <Typo type="h0" align="center" color="#ffffff">Attività</Typo> */}
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Nuova Vita ASD" : "xxx"}</Typo>
            <Typo type="p" align="center" color="#ffffff">Siamo un centro certificato dalla Scuola di Equitazione Etica, dedicato a offrire ai cavalli una vita serena ed in armonia con la loro natura. Crediamo che ogni cavallo meriti di vivere in branco, libero di socializzare, muoversi e alimentarsi secondo le sue esigenze.</Typo>
            <Typo type="p" align="center" color="#ffffff">Nel nostro centro, i cavalli non sono solo ospiti: sono parte della famiglia. Ogni dettaglio della gestione è pensato per garantire il massimo benessere fisico e psicologico, con un’attenzione particolare alle esigenze individuali di ogni cavallo.</Typo>
            <Typo type="p" align="center" color="#ffffff">Ti invitiamo a scoprire come rendiamo possibile tutto questo!</Typo>           


          </Col>
        </Row>
        <br/><br/>
        <Row>
          {/* <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToVolteggio.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-volteggio.jpg" alt="Volteggio" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Volteggio" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col> */}
          <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToBoarding.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/nuovavita-boarding.jpg" alt="Pensione cavalli" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Pensione cavalli " : "Boarding"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToHorseLife.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/nuovavita-horselife.jpg" alt="Vita da Cavallo" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Vita da Cavallo" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToMembership.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/nuovavita-membership.jpg" alt="Area Soci" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Area Soci" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
        </Row>
        <br/><br/>
      </Section>
      
      {/* <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">A chi sono rivolte</Typo>
            <Typo type="p" align="left">
              <ul>
                <li><b>Neofiti e principianti</b>: persone che non hanno mai conosciuto i cavalli o hanno fatto solo qualche lezione</li>
                <li><b>Media esperienza</b>: persone che hanno già conosciuto i cavalli ma vogliono approfondire il mondo dell'Equitazione Etica</li>
                <li><b>Esperti</b>: persone che hanno già avuto esperienze di Equitazione Etica, o di Equitazione tradizionale ma che vogliono approfondire il mondo dell'Equitazione Etica</li>
                <li><b>Proprietari e non</b>: possibilità di fare attività con il proprio cavallo o con uno del centro</li>
                <li><b>Bambini, ragazzi e adulti</b>: ogni attività è pensata e formulata in base all'età e alle capacità dei partecipanti</li>
              </ul>
            </Typo>
            <Typo type="p" align="left">
              Tutte le attività prevedono una parte da terra e alcune una parte in sella, ma a seconda della preparazione del binomio o delle proprie preferenze è possibile svolgere tutto il percorso da terra, anche per le discipline che tipicamente vengono viste solo in sella.
            </Typo>
          </Col>
        </Row>
      </Section> */}

    
      <Section variant="light">
        <ScrollRef ref={scrollToBoarding} /> 
        <Row>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/nuovavita-boarding.jpg" alt="Pensione Cavalli" width="100%" />
          </Col>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Pensione Cavalli " : "xxx"}</Typo>
            <Typo type="p">Offriamo una pensione per cavalli che combina spazi ampi, socializzazione in branco e una gestione personalizzata, per garantire benessere fisico e psicologico a ogni animale, con un approccio etico e naturale.</Typo>
            <Typo type="p">Che si tratti di cavalli giovani, anziani o con necessità particolari, ci impegniamo a fornire soluzioni su misura, sostenendo il loro benessere in ogni momento della giornata.</Typo>
            <center><Link to={"/"+lang+"/nuova-vita/boarding"}><Button>Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToHorseLife} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Vita da Cavallo" : "xxx"}</Typo>
            <Typo type="p">La vita in branco è al centro della nostra gestione. I cavalli che ospitiamo vivono in un ambiente che rispetta e valorizza le loro esigenze naturali.</Typo>
            <Typo type="p">I nostri cavalli hanno accesso continuo a fieno e acqua pulita, arricchimenti ambientali, e spazi studiati per favorire il movimento e il benessere. Ogni dettaglio è pensato per rispettare la loro natura e le esigenze individuali.</Typo>
            <center><Link to={"/"+lang+"/nuova-vita/horse-life"}><Button>Scopri di più</Button></Link></center>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/nuovavita-horselife.jpg" alt="Vita da Cavallo" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToMembership} /> 
        <Row>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/nuovavita-membership.jpg" alt="Area Soci" width="100%" />
          </Col>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Area Soci" : "xxx"}</Typo>
            <Typo type="p">Per usufruire dei servizi e partecipare all'attività dell'ASD è necessario diventare soci.</Typo>
            <Typo type="p">Diventando socio avrai diritto alla tessera dell'ASD, che include l’assicurazione fornita da ASI, ente di promozione sportiva riconosciuto dal CONI. Grazie alla tessera, potrai accedere ai servizi offerti da tutte le ASD affiliate ad ASI.</Typo>
            <center><Link to={"/"+lang+"/nuova-vita/membership"}><Button>Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>

      


      
    </Layout>
  )
}

export default Nuovavita;
