import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Link, useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Section from './../layout/Section';
import Row from './../../common/components/Row';
import Col from './../../common/components/Col';
import Card from './../../common/components/Card'
import Typo from './../../common/components/Typo'
import Button from './../../common/components/Button';
import Meta from './../layout/Meta';
// import Calendar from './../../common/components/Calendar';

import Layout from '../layout/Layout';


const ScrollTrigger = styled.div`
  height: 100%;
  cursor: pointer;
  transition: transform 50ms ease-in-out;

&:hover {
  transform: rotate(2deg);
}
`;
const ScrollRef = styled.div`
  scroll-margin: 130px;
`;

const Paths = ({layout}) => {
  let { lang } = useParams();
  
  // const scrollToVolteggio = useRef();
  const scrollToMedicazioni = useRef();
  const scrollToTrailerLoading = useRef();
  const scrollToDressageBasic = useRef();
  const scrollToEscursioneEsterna = useRef();
  const scrollToEscursioneSimulata = useRef();
  const scrollToSummerCamp = useRef();
  // const scrollToFormaFisica = useRef();
  // const scrollToSaltoOstacoli = useRef();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Percorsi" : "xxx"}
        languages={{"it": "paths"}}
      />
      <Section variant="primary" image={"/photos/headers/percorsi.jpg"}>
        <Row>
          <Col widthmd={12}>  
            {/* <Typo type="h0" align="center" color="#ffffff">Attività</Typo> */}
            <Typo type="h1" align="center" color="#ffffff">{lang === "it" ? "Percorsi" : "xxx"}</Typo>
            <Typo type="p" align="center" color="#ffffff">I nostri percorsi formativi sono strutturati come una serie di lezioni che affrontano argomenti specifici, passo dopo passo, durante ciascun incontro. Verrà quindi formato un gruppo stabile per tutta la durata del percorso.</Typo>
            <Typo type="p" align="center" color="#ffffff">I percorsi vengono svolti con un numero chiuso di partecipanti in date predeterminate che verranno inserite nel calendario. Qualora non ci fossero date in programma, è possibile contattarci per richiedere l'avvio di un nuovo percorso.</Typo>
            <Typo type="p" align="center" color="#ffffff">La prenotazione è obbligatoria!</Typo>
            <Typo type="p" align="center" color="#ffffff">Alla fine di ogni percorso, verrà rilasciato un attestato di partecipazione.</Typo>
          </Col>
        </Row>
        <br/><br/>
        <Row>
          {/* <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToVolteggio.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-volteggio.jpg" alt="Volteggio" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Volteggio" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col> */}
          <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToMedicazioni.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-medicazioni.jpg" alt="Medicazioni " width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Medicazioni " : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
           <ScrollTrigger 
              onClick={() => {
                scrollToTrailerLoading.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-trailer-loading.jpg" alt="Trailer Loading" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Trailer Loading" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToDressageBasic.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-dressage-basic.jpg" alt="Dressage (Base)" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Dressage (Base)" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>
          {/* <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToSaltoOstacoli.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-salto-ostacoli.jpg" alt="Salto Ostacoli" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Salto Ostacoli" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col> */}
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToEscursioneSimulata.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-escursione-simulata.jpg" alt="Escursione Simulata" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Escursione Simulata" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>     
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToEscursioneEsterna.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-escursione-esterna.jpg" alt="Escursione Esterna" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Escursione Esterna" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>  
          <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToSummerCamp.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card height="100%">
                <img src="/photos/thumbnails/percorso-summer-camp.jpg" alt="Summer Camp" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Summer Camp" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col>     
          {/* <Col widthmd={3}>
            <ScrollTrigger 
              onClick={() => {
                scrollToFormaFisica.current.scrollIntoView({
                  behavior: "smooth"
                });
              }}
            >
              <Card>
                <img src="/photos/thumbnails/percorso-forma-fisica.jpg" alt="Forma fisica" width="100%" />
                <br/><br/>
                <Typo type="h4" align="center" margin="5px">{lang === "it" ? "Forma fisica" : "xxx"}</Typo>              
              </Card>
            </ScrollTrigger>
          </Col> */}
        </Row>
        <br/><br/>
      </Section>
      
      <Section variant="white">
        <Row>
          <Col widthmd={12}>  
            <Typo type="h2" align="center">A chi sono rivolte</Typo>
            <Typo type="p" align="left">
              <ul>
                <li><b>Neofiti e principianti</b>: persone che non hanno mai conosciuto i cavalli o hanno fatto solo qualche lezione</li>
                <li><b>Media esperienza</b>: persone che hanno già conosciuto i cavalli ma vogliono approfondire il mondo dell'Equitazione Etica</li>
                <li><b>Esperti</b>: persone che hanno già avuto esperienze di Equitazione Etica, o di Equitazione tradizionale ma che vogliono approfondire il mondo dell'Equitazione Etica</li>
                <li><b>Proprietari e non</b>: possibilità di fare attività con il proprio cavallo o con uno del centro</li>
                <li><b>Bambini, ragazzi e adulti</b>: ogni attività è pensata e formulata in base all'età e alle capacità dei partecipanti</li>
              </ul>
            </Typo>
            <Typo type="p" align="left">
              Tutte le attività prevedono una parte da terra e alcune una parte in sella, ma a seconda della preparazione del binomio o delle proprie preferenze è possibile svolgere tutto il percorso da terra, anche per le discipline che tipicamente vengono viste solo in sella.
            </Typo>
          </Col>
        </Row>
      </Section>

    {/* <Section variant="primary">
      <Row>
        <Col widthmd={12}>  
          <Typo type="h2" align="center" color="#ffffff">Calendario</Typo>
          <Calendar 
            filter={["medication", "trailer-loading", "dressage-basic", "mock-excursion", "outdoor-excursion"]}
          />
        </Col>
      </Row>
    </Section>   */}


      {/* <Section variant="light">
        <ScrollRef ref={scrollToVolteggio} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Volteggio" : "xxx"}</Typo>
            <Typo type="p">Il volteggio è una specialità che consiste nell'eseguire esercizi ginnici a corpo libero sul cavallo condotto da terra da un'altra persona.</Typo>
            <Typo type="p">Con delicatezza e senza salti che possano nuocere alla schiena del cavallo, il volteggio diventa etico anche per il fatto che il cavallo cammina insieme ad una persona che comunica costantemente con lui con un dialogo, variando i percorsi e le velocità, senza mai costringere il cavallo in nessun modo.</Typo>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-volteggio.jpg" alt="Volteggio" width="100%" />
          </Col>
        </Row>
      </Section> */}
      <Section variant="light">
        <ScrollRef ref={scrollToMedicazioni} /> 
        <Row>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-medicazioni.jpg" alt="Medicazioni " width="100%" />
          </Col>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Medicazioni " : "xxx"}</Typo>
            <Typo type="p">Il percorso Medicazioni è pensato per trasferire tutte le conoscenze e le competenze che ho impiegato anni ad accumulare ma che idealmente bisognerebbe conoscere fin da subito.
            <br/>Le informazioni di questo corso sono frutto della mia formazione da Tecnico della SEE, della mia formazione da Tecnico di IFE, della mia esperienza come volontaria di Equinozio OdV (associazione che si occupa di salvare, curare e riabilitare cavalli) e di confronti con professionisti del settore veterinario.
            <br/><u>Attenzione</u>: <u>NON SARAI IN GRADO DI SOSTITUIRTI IN ALCUN MODO AL VETERINARIO</u>.</Typo>


            <center><Link to={"/"+lang+"/paths/medication"}><Button>Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToTrailerLoading} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Trailer Loading" : "xxx"}</Typo>
            <Typo type="p">Il Trailer Loading è un percorso in cui insegneremo al cavallo, non solo il "loading" (ovvero a salire su van o trailer), ma anche a viaggiare.
            <br/>Può sembrare scontato, perché chissà quanti viaggi avrà già fatto il tuo cavallo, ma come li ha vissuti?<br/>
            Dopo ogni viaggio scende dal trailer sudato e agitato? Con questo percorso lo aiuteremo a salire con tranquillità, a non aver paura di restare nel van e ad avere un'aspettativa positiva di dove si andrà!</Typo>
            <center><Link to={"/"+lang+"/paths/trailer-loading"}><Button>Scopri di più</Button></Link></center>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-trailer-loading.jpg" alt="Trailer Loading" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToDressageBasic} /> 
        <Row>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-dressage-basic.jpg" alt="Dressage (Base)" width="100%" />
          </Col>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Dressage (Base)" : "xxx"}</Typo>
            <Typo type="p">Il Dressage è una specialità degli sport equestri nella quale cavallo e cavaliere eseguono una serie prestabilita di figure e movimenti in campo.</Typo>
            <Typo type="p">Ottima sfida per testare la precisione della comunicazione con il cavallo e la vostra intesa, si può sempre migliorare... Ed è anche un validissimo allenamento per mantenere la forma fisica!</Typo>
            <center><Link to={"/"+lang+"/paths/dressage-basic"}><Button>Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>
      {/* <Section variant="light">
        <ScrollRef ref={scrollToSaltoOstacoli} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Salto Ostacoli" : "xxx"}</Typo>
            <Typo type="p">Il salto ostacoli è un percorso di salti, diverso per altezza e difficoltà a seconda della categoria.</Typo>
            <Typo type="p">Restando nel range di possibilità fisiche e mentali del cavallo può essere un ottimo esercizio di ginnastica e di consapevolezza del proprio corpo, sia per il cavallo che per il cavaliere. Allenamento non solo del fisico, ma anche della comunicazione, per renderla chiara e precisa, avendo un obiettivo da raggiungere insieme.</Typo>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-salto-ostacoli.jpg" alt="Salto Ostacoli" width="100%" />
          </Col>
        </Row>
      </Section> */}
      <Section variant="white">
        <ScrollRef ref={scrollToEscursioneSimulata} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Escursione Simulata" : "xxx"}</Typo>
            <Typo type="p">Questo percorso è pensato per le persone che non hanno ancora raggiunto i requisiti minimi (di età o di competenze) per partecipare alle escursioni esterne e sarà una vera e propria escursione, ma simulata.<br/>
            In questo modo restaremo all'interno del centro, in sicurezza, riproducendo tutte le modalità che servono per l'escursione vera e propria e tutte le difficoltà e gli imprevisti che si potrebbero trovare all'esterno.</Typo>
            <center><Link to={"/"+lang+"/paths/mock-excursion"}><Button>Scopri di più</Button></Link></center>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-escursione-simulata.jpg" alt="Escursione Simulata" width="100%" />
          </Col>
        </Row>
      </Section>
      <Section variant="light">
        <ScrollRef ref={scrollToEscursioneEsterna} /> 
        <Row>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-escursione-esterna.jpg" alt="Escursione Esterna" width="100%" />
          </Col>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Escursione Esterna" : "xxx"}</Typo>
            <Typo type="p">Nel percorso Escursione Esterna si esplora l'ambiente esterno al centro insieme ai cavalli, uscendo in passeggiata.
            <br/>L'escursione è una camminata in natura, a piedi e/o in sella, di durata variabile. Si esce in gruppi misti di cavalli e umani, entrambi dalle età ed esperienze varie, anche cani e altri animali sono ben accetti.<br/>
            Offriamo ai cavalli la possibilità di fare attività che in natura praticherebbero per gran parte del tempo mentre nella gestione domestica, seppur naturalizzata, sono limitate; le escursioni sono attività che allineano le motivazioni di specie equina ed umana.</Typo>
            <center><Link to={"/"+lang+"/paths/outdoor-excursion"}><Button>Scopri di più</Button></Link></center>
          </Col>
        </Row>
      </Section>
      <Section variant="white">
        <ScrollRef ref={scrollToSummerCamp} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Summer Camp" : "xxx"}</Typo>
            <Typo type="p">Porta i tuoi bambini al nostro Summer Camp!<br/>Si divertiranno e impareranno, stando all’aria aperta, a conoscere i cavalli e il lavoro di squadra, parteciperanno a laboratori e giochi d’acqua, e faranno tante nuove amicizie ed esperienze!</Typo>
            <center><Link to={"/"+lang+"/paths/summer-camp"}><Button>Scopri di più</Button></Link></center>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-summer-camp.jpg" alt="Summer Camp" width="100%" />
          </Col>
        </Row>
      </Section>
      {/* <Section variant="light">
        <ScrollRef ref={scrollToFormaFisica} /> 
        <Row>
          <Col widthmd={8}>  
            <Typo type="h2" align="center">{lang === "it" ? "Forma Fisica" : "xxx"}</Typo>
            <Typo type="p">xxx</Typo>
          </Col>
          <Col widthmd={4}>  
            <img src="/photos/thumbnails/percorso-forma-fisica.jpg" alt="Forma Fisica" width="100%" />
          </Col>
        </Row>
      </Section> */}

      


      
    </Layout>
  )
}

export default Paths;
