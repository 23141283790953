import React, {useEffect} from 'react'
// import {Link} from 'react-router-dom';
import styled from 'styled-components'
import {useParams} from 'react-router-dom';

import AnalyticsService from './../../services/AnalyticsService';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../layout/Layout'
import Section from '../layout/Section'
import Typo from '../../common/components/Typo'
import Meta from './../layout/Meta';

const Map = styled.div`
  & *{
    display: block;
  }
`;


const ContactsPage = ({layout}) => {
  let { lang } = useParams();

  useEffect(() => {
    AnalyticsService.init();  
  }, []);

  let iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.8602866773285!2d10.77504681572498!3d45.04805696913462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47802b4e24a0d651%3A0x8c0b82c5f835ef8!2sEquinozioOnlus%20-%20Nuova%20Vita%20ASD%20-%20Equitazione%20etica!5e0!3m2!1sit!2sit!4v1673458964318!5m2!1sit!2sit" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

  return (
    <Layout layout={layout}>
      <Meta
        title={lang === "it" ? "Contatti" : "Contacts"}
        languages={{"it": "contatti", "en":"contacts"}}
      />

      
      <Section variant="light">
        <Typo type="h1" align="center">{lang === "it" ? "Contatti" : "Contacts"}</Typo>
        {lang === "it" ?
          <>
            <Typo type="p" align="center">Il centro Nuova Vita ASD si trova a Borgo Virgilio, in località Boccadiganda.<br/>Vieni a trovarci, saremo felici di accoglierti e farti conoscere il nostro branco!</Typo>
          </>
        : null }
      </Section>
      <Section variant="peach">
        <Row>
          <Col md={6}>
            <Typo type="h3" align="center">Indirizzo</Typo>
            <Typo type="p" align="center"><b>Nuova Vita ASD</b><br/>Via Argine Boccadiganda 115,<br/>Borgo Virgilio 46034 (MN)</Typo>
          </Col>
          <Col md={6}>
            <Typo type="h3" align="center">Contatti</Typo>
            <Typo type="p" align="center">
              <b>Martina Passanante</b><br/>
              Telefono: <a href="tel:+393461330641" target="_blank" rel="noreferrer">+39 346 1330641</a><br/>
              Email: <a href="mailto:info@martinapassanante.it" target="_blank" rel="noreferrer">info@martinapassanante.it</a>
            </Typo>
            <Typo type="p" align="center">
              <b>Nuova Vita ASD</b><br/>
              Email: <a href="mailto:nuovavitaasd@gmail.com" target="_blank" rel="noreferrer">nuovavitaasd@gmail.com</a>
            </Typo>
          </Col>
        </Row>
      </Section>
      <Map><div dangerouslySetInnerHTML={{__html: iframe}} /></Map>
    </Layout>
  )
}

export default ContactsPage
